
.treeEffects{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tree{
  position: absolute;
  --base: polygon(43% 100%, 46% 97%, 47% 93%, 47% 77%, 47% 67%, 47% 58%, 47% 48%, 47% 35%, 46% 32%, 43% 31%, 40% 31%, 37% 33%, 35% 37%, 34% 45%, 33% 52%, 33% 58%, 33% 66%, 32% 72%, 31% 65%, 31% 58%, 31% 51%, 32% 43%, 33% 36%, 35% 32%, 39% 29%, 43% 29%, 40% 28%, 36% 28%, 32% 31%, 27% 39%, 26% 46%, 26% 51%, 25% 57%, 25% 64%, 25% 71%, 24% 77%, 23% 70%, 23% 63%, 23% 55%, 24% 49%, 25% 39%, 28% 34%, 31% 29%, 34% 27%, 38% 26%, 42% 26%, 37% 25%, 32% 25%, 27% 28%, 24% 31%, 21% 37%, 20% 44%, 19% 51%, 18% 58%, 18% 65%, 17% 71%, 16% 64%, 16% 56%, 17% 49%, 18% 42%, 19% 36%, 23% 29%, 27% 26%, 32% 23%, 38% 23%, 46% 25%, 49% 25%, 54% 23%, 60% 22%, 65% 22%, 70% 24%, 74% 28%, 77% 35%, 78% 43%, 78% 51%, 79% 60%, 79% 69%, 78% 77%, 77% 68%, 77% 59%, 76% 50%, 76% 44%, 75% 36%, 73% 31%, 69% 26%, 64% 24%, 56% 25%, 52% 27%, 58% 26%, 62% 27%, 65% 30%, 67% 35%, 68% 40%, 69% 45%, 69% 52%, 69% 64%, 68% 71%, 67% 63%, 67% 56%, 67% 48%, 67% 45%, 65% 36%, 64% 32%, 61% 29%, 57% 28%, 52% 29%, 56% 29%, 59% 31%, 61% 35%, 62% 41%, 62% 50%, 62% 59%, 61% 66%, 61% 72%, 60% 76%, 59% 72%, 59% 65%, 60% 58%, 60% 52%, 60% 46%, 60% 41%, 59% 37%, 58% 34%, 56% 32%, 53% 31%, 51% 32%, 50% 35%, 50% 46%, 50% 57%, 50% 67%, 50% 78%, 50% 93%, 51% 97%, 54% 100%);
  --left1: polygon(43% 100%, 46% 97%, 47% 93%, 47% 77%, 46.90% 67%, 46.70% 58%, 46.50% 48%, 46% 35%, 45% 32%, 42% 31%, 39% 31%, 36% 33%, 34% 37%, 33% 45%, 32% 52%, 31% 58%, 29% 66%, 27% 71%, 27% 65%, 29% 58%, 30% 51%, 31% 43%, 32% 36%, 34% 32%, 38% 29%, 42% 29%, 39% 28%, 35% 28.10%, 31% 31.20%, 26% 39%, 25% 46%, 24% 51%, 23% 57%, 21% 64%, 18% 70%, 14% 73%, 16% 69%, 19% 63%, 21% 55%, 22% 49%, 24% 39%, 27% 34%, 30% 29%, 33% 27%, 37% 26.20%, 41% 26.10%, 36% 25.20%, 31% 25.30%, 26% 28.30%, 23% 31.50%, 20% 37%, 18% 44%, 16% 51%, 14% 58%, 11% 63%, 7% 67%, 10% 61%, 12% 56%, 14% 49%, 16% 42%, 18% 36%, 22% 29%, 25% 26.40%, 31% 23.60%, 37% 23.30%, 44% 25%, 47% 25%, 53% 22.80%, 59% 21.70%, 64% 21.70%, 69% 23.70%, 73% 28%, 76% 35%, 77% 43%, 77% 51%, 76% 60%, 74% 69%, 72% 74%, 72% 68%, 74% 59%, 75% 50%, 75% 44%, 74% 36%, 72% 30.50%, 68% 25.70%, 63% 23.70%, 55% 24.70%, 51% 26.70%, 57% 25.80%, 61% 26.70%, 64% 30%, 66% 35%, 67% 40%, 67% 45%, 67% 52%, 65% 64%, 63% 69%, 63.50% 63%, 64.50% 56%, 65.40% 48%, 65.50% 42%, 65% 37%, 63% 31.70%, 60% 28.70%, 56% 27.70%, 51% 28.80%, 55% 29%, 58% 31%, 60% 35%, 61% 41%, 61% 50%, 60% 59%, 58% 66%, 56% 70%, 54% 73%, 54% 70%, 56% 65%, 58% 58%, 59% 52%, 59% 46%, 59% 41%, 58% 37%, 57% 34%, 55% 32%, 52% 31%, 50% 32%, 49% 35%, 49.50% 46%, 49.70% 57%, 49.90% 67%, 50% 78%, 50% 93%, 51% 97%, 54% 100%);
  --left2: polygon(43% 100%, 46% 97%, 47% 93%, 46.60% 77%, 46.20% 67%, 45.70% 58%, 44.80% 48%, 44% 35%, 43% 32%, 40% 31%, 37% 31%, 34% 33%, 31% 37%, 29% 45%, 27% 52%, 26% 58%, 23% 66%, 20% 69%, 21.50% 65%, 23.60% 58%, 25% 51%, 27% 43%, 29% 36%, 31.60% 32.40%, 36% 29.30%, 40% 29.30%, 37% 28.20%, 33% 28.40%, 29% 31.50%, 23% 39%, 20% 46%, 18% 51%, 15% 57%, 12% 63%, 7% 68%, 3% 70%, 5% 67%, 9% 63%, 14% 54%, 16% 49%, 22% 36.10%, 25% 32.50%, 28.60% 29%, 31% 27.30%, 35% 26.40%, 39% 26.30%, 34% 25.40%, 29% 25.50%, 24% 28.50%, 20% 31.70%, 16% 37%, 13% 44%, 10% 51%, 7% 58%, 3% 63%, 0% 64%, 2% 61%, 6% 56%, 8% 49%, 11% 42%, 14% 35.40%, 19.50% 29%, 22.60% 26.40%, 28% 23.80%, 35% 23.40%, 42% 25%, 45% 24.90%, 51% 22.60%, 57% 21.50%, 62% 21.50%, 67% 23.50%, 70% 27.90%, 72% 35%, 72% 43%, 70% 51%, 67% 58%, 64% 64%, 60% 68%, 61% 64%, 63% 60%, 68% 51%, 70% 44%, 70% 36%, 69% 30.30%, 66% 25.50%, 61% 23.50%, 53% 24.50%, 49% 26.50%, 55% 25.60%, 59% 26.50%, 62% 29.80%, 63% 34.90%, 63% 40%, 62% 45%, 59% 51%, 54% 59%, 49% 64%, 49.50% 61%, 53.50% 56%, 58.40% 48%, 60.50% 42%, 61% 37%, 60% 31.50%, 58% 28.50%, 54% 27.60%, 49% 28.70%, 53% 28.90%, 56% 30.90%, 57% 34.90%, 57% 41%, 54% 48%, 49% 55%, 45% 59%, 42% 60.50%, 38% 60%, 41% 59%, 45% 56%, 50% 50%, 53% 46%, 55% 42%, 55% 39%, 55% 36%, 54.80% 33.80%, 53% 31.80%, 49.90% 31%, 48% 32%, 47% 35%, 47.50% 46%, 48.40% 57%, 49.10% 67%, 49.60% 78%, 50% 93%, 51% 97%, 54% 100%);
  --right1: polygon(43% 100%, 46% 97%, 47% 93%, 47% 77%, 47.10% 67%, 47.30% 58%, 47.50% 48%, 48% 35%, 47% 32%, 44% 31%, 41% 31%, 38% 33%, 36% 37%, 36% 45%, 35% 52%, 35% 58%, 36% 66%, 35% 72%, 34% 65%, 33% 58%, 33% 51%, 34% 43%, 34% 36%, 36% 32%, 40% 29%, 44% 29%, 41% 28%, 37% 28%, 33% 31%, 29% 39%, 28% 46%, 29% 51%, 29% 57%, 29% 64%, 30% 71%, 29% 77%, 28% 70%, 27% 63%, 27% 55%, 26% 49%, 27% 39%, 29% 34%, 32% 29%, 35% 27%, 39% 26%, 43% 26%, 38% 24.70%, 33% 24.60%, 28% 27.70%, 25% 30.80%, 23% 37%, 22% 44%, 22% 51%, 22% 58%, 24% 65%, 24% 71%, 22% 64%, 20% 56%, 20% 49%, 20% 42%, 21% 36%, 24% 29%, 28% 25.80%, 33% 22.80%, 39% 22.80%, 47% 25%, 50% 25%, 55% 23%, 61% 22.20%, 66% 22.30%, 71% 24.40%, 75% 28.40%, 79% 35%, 81% 43%, 82% 51%, 84% 58%, 86% 67%, 89% 75%, 84% 67%, 82% 58%, 80% 50%, 79% 44%, 77% 36%, 74% 31.10%, 70% 26.20%, 65% 24.20%, 57% 25%, 53% 27%, 59% 26%, 63% 27%, 66% 30%, 69% 35%, 71% 40%, 73% 45%, 75% 51%, 77% 62%, 79% 69%, 75% 62%, 74% 55%, 72% 48%, 71% 45%, 67% 36%, 65% 32%, 62% 29%, 58% 28%, 53% 29%, 57% 29%, 60% 31%, 62% 35%, 64% 41%, 65% 50%, 67% 58%, 70% 65%, 73% 70%, 75% 73%, 71% 70%, 68% 65%, 65% 58%, 64% 52%, 63% 46%, 62% 41%, 60% 37%, 59% 34%, 57% 32%, 54% 31%, 52% 32%, 51% 35%, 50.50% 46%, 50.30% 57%, 50.10% 67%, 50% 78%, 50% 93%, 51% 97%, 54% 100%);
  --right2: polygon(43% 100%, 46% 97%, 47% 93%, 47.40% 77%, 47.80% 67%, 48.30% 58%, 49.20% 48%, 50% 35%, 49% 32%, 46% 30.80%, 43% 30.70%, 40.50% 32.70%, 39% 37%, 40% 45%, 43% 51%, 48% 56%, 53% 59%, 56% 60%, 51% 60%, 47% 58%, 41% 52%, 37.20% 44%, 36.70% 36%, 38% 31.80%, 42% 28.80%, 46% 28.80%, 43% 27.80%, 39% 27.80%, 35.50% 30.80%, 33% 39%, 34% 46%, 36% 51%, 40% 57%, 44% 61%, 47% 62%, 50% 63%, 46% 63.60%, 42% 62%, 36% 55%, 33% 49%, 31% 39%, 32% 34%, 34.30% 28.80%, 37.10% 26.80%, 40.80% 25.80%, 45% 25.80%, 40% 24.50%, 35% 24.40%, 30.50% 27.50%, 28% 30.80%, 27% 37%, 27% 44%, 29% 50%, 32% 56%, 35% 59%, 39% 60%, 35% 61%, 31% 58%, 26% 49%, 25% 42%, 25% 36%, 26.50% 28.80%, 30% 25.60%, 35% 22.60%, 41% 22.70%, 49% 25%, 52% 25%, 57% 23%, 63% 22.40%, 68% 22.50%, 73% 24.70%, 77.50% 28.30%, 82% 35%, 85% 43%, 87% 51%, 91% 57%, 95% 60%, 100% 61%, 95% 62%, 90% 59%, 85% 52%, 83% 44%, 80% 36%, 77% 31.10%, 72.30% 26.10%, 67% 24%, 59% 24.80%, 55% 26.80%, 61% 25.80%, 65% 26.80%, 68.50% 29.90%, 72% 35%, 75% 40%, 78% 45%, 82% 51%, 86% 55%, 91% 57%, 86% 57%, 81% 53%, 77% 48%, 75% 45%, 70% 36%, 67.60% 32%, 64.60% 28.90%, 60% 27.80%, 55% 28.80%, 59% 28.80%, 62% 30.80%, 64.60% 34.90%, 67% 41%, 71% 50%, 76% 56%, 81% 59%, 84% 60%, 87% 60%, 84% 62%, 80% 61%, 75% 58%, 70% 52%, 67% 46%, 65% 41%, 63% 37%, 61.50% 34%, 59.30% 31.90%, 56% 31%, 54% 32%, 53% 35%, 51.90% 46%, 51.10% 57%, 50.50% 67%, 50.20% 78%, 50% 93%, 51% 97%, 54% 100%);
  width: 70vh;
  height: 70vh;
  max-width: 80vw;
  max-height: 80vw;
  left: 10px;
  z-index: 5;
  bottom: -5px;
  background:rgb(98, 49, 34);
  clip-path: var(--base);
  animation-delay: -0.5s;
  animation: treeSway 1s linear;
  animation-play-state: paused;
}
@keyframes treeSway{
  0%  {clip-path: var(--left2)}
  25% {clip-path: var(--left1)}
  50% {clip-path: var(--base)}
  75% {clip-path: var(--right1)}
  100%{clip-path: var(--right2)}
}
.leafContainer, .leaf, .blossom{
  position: absolute;
  width: 35px;
  height: 35px;
  background-size: 100% 100%;
}
.leafContainer{
  animation: leafFall linear infinite;
}
.leaf, .blossom{
  animation: linear infinite, alternate-reverse infinite;
}
.leaf{
  background-image: url("../../images/cherryLeaf.png")
}
.blossom{
  background-image: url("../../images/cherryBlossom.png")
}
@keyframes leafFall{ /* Applied to container */
  0%   {top: -40px; }
  100% {top: 101%; }
}
@keyframes leafSway1{ /* Applied to leaf */
  0%, 100% {left: 0px; }
  25%  {left: -20px;}
  75% {left: 20px;}
}
@keyframes leafSway2{ /* Applied to leaf */
  0%, 100% {left: 0px; }
  35%  {left: -30px;}
  85% {left: 20px;}
}
@keyframes leafSway3{ /* Applied to leaf */
  0%, 100% {left: 0px; }
  20%  {left: 20px;}
  65% {left: 50px;}
}
@keyframes leafSpin1{
  0% {transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);}
  100% {transform: rotateX(720deg) rotateZ(360deg) rotateY(360deg);}
}
@keyframes leafSpin2{
  0% {transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);}
  100% {transform: rotateX(360deg) rotateZ(720deg) rotateY(360deg);}
}
@keyframes leafSpin3{
  0% {transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);}
  100% {transform: rotateX(360deg) rotateZ(360deg) rotateY(720deg);}
}
/*------------- text -----------------*/
.text{
  position: absolute;
  height: 100%;
  top: 0%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 90% 5% 5%;
  grid-template-areas: "t1 t2 t3";
  transition: 1s;
}
.text .textTop{
  position: absolute;
  width: 100vw;
  height: 4vh;
  top: 0%;
  background: linear-gradient(180deg, #f5e1b9 0%, #f5e1b900 100%);
}
.t1{grid-area: t1;}
.t2{grid-area: t2;}
.t3{grid-area: t3;}
.text div{
  position: relative;
  height: 100%;
  overflow: hidden;
  transition: 1s;
  z-index: 8;
}
.text div div{
  display: flex;
  width: calc(90vw - var(--scrollbar-width));
  padding: 2% 5vw;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}