html{
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
}
body{
  overflow-x: hidden;
  overflow-y: scroll;
}
.App{
  text-align: center;
}
.splashContainer{
  position: relative;
  height: 100vh;
}
.splash{
  position: absolute;
  width:100%;
  height:100%;
  background: red;
  transition: 1s;
  z-index: 10;
}
/* -------------------- Menu --------------------- */
.menuCont{
  position:absolute;
  width: 100%;
  height: 200vh;
}
#menu{
  position: sticky;
  display: block;
  left: 0%;
  width: 100%;
  height: 30px;
  margin: 10px 0;
}
.menuButton{
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  padding: 0px;
  position: absolute;
}
.menuButtonPanel{
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  border: solid 1px black;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-clip: padding-box;
}
.spinContainer{
  position: sticky;
  height: 12vw;
  perspective: calc(600px - 6vw);
  z-index: 1;
  top: calc(50% - 6vw);
}
.helloSpin, .helloSpinLeft{
  position: relative;
  height: 12vw;
  perspective: 600px;
  vertical-align: baseline;
  font-size: 6vw;
  transform: translateZ(-6vw)
}
.helloSpin{
  top: -12vw;
  width: 60%;
  left: 30%;
  perspective-origin: 34%;
  text-align: left;
}
.helloSpinLeft{
  top: 0px;
  width: 20%;
  left: 10%;
  perspective-origin: 200%;
  text-align: right;
}
.helloSpinPanel{
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  right: 0%;
  background-color: green;
  line-height: 11vw;
}
.helloSpinPanelRight{
  position: absolute;
  width: 12vw;
  height: 12vw;
  right: -6vw;
  background: rgb(96,72,45);
  background: radial-gradient(circle, rgba(96,72,45,1) 0%, rgba(120,100,78,1) 53%, rgba(96,72,45,1) 63%, rgba(120,100,78,1) 73%, rgba(96,72,45,1) 79%, rgba(120,100,78,1) 85%, rgba(96,72,45,1) 91%, rgba(120,100,78,1) 96%, rgba(96,72,45,1) 100%);}
#Hello{
  background-color: green;
  padding: 0 0 30vh 0;
  width: 100%;
  height: max(200vh, 1000px);
  top: 0;
  box-sizing: border-box;
  background-image: url("../../images/sunbeam.jpg");
  background: fixed;
}
.hellobg{
  position:absolute;
  background: fixed;
  height:200vh;
  width:100%;
  padding: 0;
}
.panel1{
  background: url("../../images/sunbeam.jpg") 0 0 /1920px;
}
.panel3{
  background: url("../../images/trees.jpg") 0 0 /1920px;
}
.panel2{
  background: url("../../images/penguin.jpg") 0 0 /1920px;
  color:#000;
  text-shadow: 1px 1px 0px #888,
               -1px -1px 0px #888,
               -1px 1px 0px #888,
               1px -1px 0px #888;
}
.panel1, .panel3{
  color:#99865f;
  text-shadow: 1px 1px 0 #232916,
               -1px -1px 0 #232916,
               -1px 1px 0 #232916,
               1px -1px 0 #232916;
}
.panel3.bgNone{
  background: none;
}
/* ------------------- Tabs (mostly) --------------- */
section{
  height: calc(100% - 50px);
  top: 50px;
  width: 100%;
  position: absolute;
  pointer-events: all;
}
article{
  position: absolute;
  top: 0px;
  height: 100%;
  font-size: min(2vw, 2vh);
}
#About, .tabTop3{
  background-color: #5f6f3a;
}
#Projects, .tabTop1{
  background-color: #8e7d69;
}
#Animation, .tabTop2{
  background-color: #d8af7f;
}
#Contact, .tabTop0{
  background-color: #f5e1b9;
}
#Contact, #About{
  overflow: hidden;
}
/* Just note that the below button names have the above labels for the user */
.contactButton .menuButtonPanel{
  background-color: #5f6f3a;
}
.aboutButton .menuButtonPanel{
  background-color: #8e7d69;
}
.projectsButton .menuButtonPanel{
  background-color: #d8af7f;
}
.helloButton .menuButtonPanel{
  background-color: #f5e1b9;
}
a{
  color: black;
}
#tabSection{
  height: 100vh;
  top: calc(max(200vh, 1000px) - 50px);
  width: 100%;
  position: absolute;
  z-index: 2;
}
.tabHolder{
  position: absolute;
  width: 100%;
  height: 200%;
  bottom: 0px;
  overflow: hidden;
  pointer-events: none;
}
.tab{
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0px;
  pointer-events: none;
  transform-origin: 0 50px;
  transform: rotateZ(0deg) translateX(0px);
  transition: transform 0.3s;
}
.tabUp{
  transform: rotateZ(-95deg) translateX(45px);
}
.tab0{ z-index: 1;}
.tab1{ z-index: 2;}
.tab2{ z-index: 3;}
.tabFront{
  transform: rotateZ(0deg) translateX(0px);
  z-index: 4;
}
.tabTopBack{
  position: absolute;
  height: 200px;
  width: 100%;
  top: -150px;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%);
}
.tabTop{
  clip-path: polygon(0 100%, 6% 96%, 11% 83%, 12% 75%, 15% 21%, 17% 11%, 20% 3%, 25% 0, 75% 0, 80% 3%, 83% 11%, 85% 21%, 88% 75%, 89% 83%, 94% 96%, 100% 100%);
  display: inline-block;
  height: 40px;
  top: 11px;
  line-height: 30px;
  font-size: 1.05em;
  padding: 0;
  margin: 0 0 0 -5px;
  transform-origin: 0% 7.3%;
  position: absolute;
  z-index: 1;
  animation: tabMorph 1s linear 1;
  animation-play-state: paused;
  pointer-events: all;
  cursor: pointer;
}
@keyframes tabMorph{
  100%     { clip-path: polygon(0 100%, 6% 96%, 11% 83%, 12% 75%, 15% 21%, 17% 11%, 20% 3%, 25% 0, 75% 0, 80% 3%, 83% 11%, 85% 21%, 88% 75%, 89% 83%, 94% 96%, 100% 100%); }
  0%   { clip-path: polygon(12% 83%, 12% 83%, 12% 83%, 12% 75%, 12% 21%, 12% 0%, 20% 0%, 25% 0, 75% 0, 80% 0%, 89% 0%, 89% 21%, 89% 75%, 89% 83%, 89% 83%, 89% 83%); }
}