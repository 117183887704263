@import url('https://fonts.googleapis.com/css?family=Cambay:400');
.typebg{
  width: 100%;
  height: 100%;
  background-image: url("../../images/big3.jpg");
  background-size: cover;
}
.typebgside{
    background: #8e7d69;
    height: 100%;
    width: 300px;
    left: 100%;
    position: absolute;
}
.words{
    display: block;
    position: absolute;
    width: auto;
    /* left: 85%; */
}
.move{
    animation: goLeft 4s linear; /* forwards; */
}
@keyframes goLeft {
  0% {left: 100%;}
  100% {left: 0%;}
}
.preLetters, .untypedLetters, .typedLetters{
    display: inline-block;
    position: relative;
    line-height: 15px;
}
.preLetters, .untypedLetters{
    color: black;
    background: white;
    border: solid white;
    box-shadow: white 0px 0px 6px 1px;
}
.preLetters{
    border-radius: 50px 50px 50px 50px;
    border-width: 2px 10px 4px 10px;
}
.untypedLetters{
    border-radius: 0 50px 50px 0;
    border-width: 2px 10px 4px 0px;
}
.typedLetters, .paused div{
    color: red;
    background: aqua;
    border: solid aqua;
    box-shadow: aqua 0px 0px 6px 1px;
}
.typedLetters{
    border-radius: 50px 0 0 50px;
    border-width: 2px 0px 4px 10px;
    z-index: 1;
}
.paused div{
    border-radius: 50px 50px 50px 50px;
    border-width: 2px 3px 4px 3px;
    line-height: 15px;
    position: absolute;
    animation: die 1s linear forwards;
    animation-play-state: running;
}
@keyframes die {
    0%   {background: aqua;  color: red;    box-shadow: aqua 0px 0px 6px 1px;  border: solid aqua;}
    70%  {opacity: 100%}
    100% {background: black; color: maroon; box-shadow: black 0px 0px 6px 1px; border: solid black; opacity: 0%}
}
.noLetters{
    display: none;
}
.paused {
    animation-play-state: paused;
}